/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Renovation from "assets/images/products/renovation.jpeg";
import Building from "assets/images/products/building.jpg";
import Planning from "assets/images/products/Planning.jpg";
import MaintenanceElektronik from "assets/images/products/maintenance - elektronik.jpg";
import MaintenanceMaterial from "assets/images/products/maintenance-Material Handling.jpg";
import MaintenanceSafety from "assets/images/products/maintenance- Safety.jpg";
import GoodsFurniture from "assets/images/products/Goods-Furniture.jpg";
import GoodsItElectronik from "assets/images/products/Goods- IT dan electronik.jpg";
import GoodsMarchandise from "assets/images/products/goods-Marchandise.jpg";
import GoodsPackaging from "assets/images/products/Goods-Packaging.jpg";
import GoodsSafety from "assets/images/products/Goods-Safety.jpeg";
import GoodsStationary from "assets/images/products/Goods-Stationary.jpg";
import InstalasiColdRoom from "assets/images/products/Instalasi -cold room.jpeg";
import InstalasiAC from "assets/images/products/Instalasi- AC.jpg";

export default [
  {
    title: "Civil",
    description:
      "Sandio Solution started civil work in 2023 as part of its business transformation. Sandio Solution has been trusted to work on civil works projects by various Clients",
    items: [
      {
        image: Planning,
        name: "Planning",
        //count: 10,
        //route: "/sections/page-sections/page-headers",
      },
      {
        image: Building,
        name: "Buinding",
        //count: 14,
        //route: "/sections/page-sections/features",
      },
      {
        image: Renovation,
        name: "Renovation",
        //count: 8,
        //pro: true,
      },
    ],
  },
  {
    title: "Installation",
    description: "Sandio solution specializes in professional and trusted installation services.",
    items: [
      {
        image: InstalasiAC,
        name: "Electrical",
        //count: 4,
        //route: "/sections/navigation/navbars",
      },
      {
        image: InstalasiColdRoom,
        name: "Cold Room",
        //count: 2,
        //route: "/sections/navigation/nav-tabs",
      },
    ],
  },
  {
    title: "Maintenance",
    description: "Sandio Solution is ready to maintain routine client needs",
    items: [
      {
        image: MaintenanceElektronik,
        name: "Electronic",
        //count: 6,
        //pro: true,
      },
      {
        image: MaintenanceMaterial,
        name: "Material Handling Equipment",
        //count: 8,
        //pro: true,
      },
      {
        image: MaintenanceSafety,
        name: "Safety Equipment",
        //count: 3,
        //route: "/sections/input-areas/forms",
      },
    ],
  },
  {
    title: "Purchase of Goods",
    description:
      "Sandio Solution collaborates with more than 100 suppliers to meet goods needs in various locations",
    items: [
      {
        image: GoodsItElectronik,
        name: "IT & electronic",
        //count: 4,
        //route: "/sections/attention-catchers/alerts",
      },
      {
        image: GoodsStationary,
        name: "Stationary and Pantry",
        //count: 3,
        //pro: true,
      },
      {
        image: GoodsMarchandise,
        name: "Custom marchandines",
        //count: 2,
        //route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: GoodsPackaging,
        name: "Packaging",
        //count: 5,
        //route: "/sections/attention-catchers/modals",
      },
      {
        image: GoodsFurniture,
        name: "Interior and Furniture",
        //count: 2,
        //route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: GoodsSafety,
        name: "Protection & Safety",
        //count: 5,
        //route: "/sections/attention-catchers/modals",
      },
    ],
  },
];
