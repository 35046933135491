// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
//import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
//import SignIn from "layouts/pages/authentication/sign-in";

// Sections
import PageHeaders from "layouts/sections/page-sections/page-headers";
import Features from "layouts/sections/page-sections/featuers";
import Navbars from "layouts/sections/navigation/navbars";
import NavTabs from "layouts/sections/navigation/nav-tabs";
import Pagination from "layouts/sections/navigation/pagination";
import Inputs from "layouts/sections/input-areas/inputs";
import Forms from "layouts/sections/input-areas/forms";
import Alerts from "layouts/sections/attention-catchers/alerts";
import Modals from "layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
//import Presentation from "layouts/pages/presentation";
//import Presentation from "pages/Presentation";

const routes = [
  // {
  //   name: "Home",
  //   icon: <Icon>dashboard</Icon>,
  // columns: 1,
  // rowsPerColumn: 2,
  //route: "/",
  //component: <Presentation />,
  // },
  {
    name: "Company Profile",
    icon: <Icon>article</Icon>,
    route: "/pages/landing-pages/about-us",
    component: <AboutUs />,
  },
  {
    name: "Product",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "Civil",
        description: "See all civil",
        dropdown: true,
        collapse: [
          {
            name: "Planning",
            //route: "/sections/page-sections/page-headers",
            component: <PageHeaders />,
          },
          {
            name: "Buinding",
            //route: "/sections/page-sections/features",
            component: <Features />,
          },
          {
            name: "Renovation",
            //route: "/sections/page-sections/features",
            component: <Features />,
          },
        ],
      },
      {
        name: "Installation",
        description: "See all instalation",
        dropdown: true,
        collapse: [
          {
            name: "Electrical ",
            //route: "/sections/navigation/navbars",
            component: <Navbars />,
          },
          {
            name: "Cold Room",
            //route: "/sections/navigation/nav-tabs",
            component: <NavTabs />,
          },
          {
            name: "Walpaper Room",
            //route: "/sections/navigation/pagination",
            component: <Pagination />,
          },
        ],
      },
      {
        name: "Maintenance",
        description: "See all Maintenance",
        dropdown: true,
        collapse: [
          {
            name: "Electronic",
            //route: "/sections/input-areas/inputs",
            component: <Inputs />,
          },
          {
            name: "Material Handling Equipment",
            //route: "/sections/input-areas/forms",
            component: <Forms />,
          },
          {
            name: "Safety",
            //route: "/sections/input-areas/forms",
            component: <Forms />,
          },
        ],
      },
      {
        name: "Purchase of goods",
        description: "See all",
        dropdown: true,
        collapse: [
          {
            name: "IT & electronic",
            //route: "/sections/attention-catchers/alerts",
            component: <Alerts />,
          },
          {
            name: "Stationary and Pantry",
            //route: "/sections/attention-catchers/modals",
            component: <Modals />,
          },
          {
            name: "Custom marchandines",
            //route: "/sections/attention-catchers/tooltips-popovers",
            component: <TooltipsPopovers />,
          },
          {
            name: "Packaging",
            //route: "/sections/attention-catchers/modals",
            component: <Modals />,
          },
          {
            name: "Interior and Furniture",
            //route: "/sections/attention-catchers/tooltips-popovers",
            component: <TooltipsPopovers />,
          },
          {
            name: "POSM & Printing Material",
            //route: "/sections/attention-catchers/modals",
            component: <Modals />,
          },
          {
            name: "Protection & Safety",
            //route: "/sections/attention-catchers/tooltips-popovers",
            component: <TooltipsPopovers />,
          },
        ],
      },
    ],
  },
  {
    name: "Gallery",
    icon: <Icon>article</Icon>,
    route: "/pages/landing-pages/galery",
    component: <Author />,
  },
  {
    name: "Contact",
    icon: <Icon>article</Icon>,
    route: "/pages/landing-pages/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
