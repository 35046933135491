/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import client1Logo from "assets/images/logos/client1.jpeg";
import client2Logo from "assets/images/logos/client2.png";
import client3Logo from "assets/images/logos/client3.png";
import client4Logo from "assets/images/logos/client4.png";
import client5Logo from "assets/images/logos/client5.jpg";
import client7Logo from "assets/images/logos/client7.jpeg";
import client8Logo from "assets/images/logos/client8.jpeg";
import client9Logo from "assets/images/logos/client9.png";
import client6Logo from "assets/images/logos/riung.jpeg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Our Happy Partner</MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Many Fortune 500 companies, startups, universities and governmental institutions love
            Creative Tim&apos;s products.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Nada @shipper"
              date="1 day ago"
              review="This is an excellent product, the team is excellent and helped me get things done more efficiently."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Assyifa @Riung"
              date="1 week ago"
              review="I found solution to all my projects from Sandio Solution. I use them as a vendor in my projects! And its really affordable, very humble guys !!!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Edo Kurniawan @Pakde"
              date="3 weeks ago"
              review="Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for company who want to spend more time on the business!."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={8} md={3} lg={3}>
            <MKBox component="img" src={client1Logo} alt="Apple" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={8} md={3} lg={3}>
            <MKBox component="img" src={client4Logo} alt="Vodafone" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={8} md={3} lg={3}>
            <MKBox
              component="img"
              src={client5Logo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid>
          <Grid item xs={8} md={3} lg={3}>
            <MKBox component="img" src={client6Logo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={8} md={3} lg={3}>
            <MKBox component="img" src={client3Logo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={8} md={3} lg={3}>
            <MKBox component="img" src={client7Logo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={8} md={3} lg={3}>
            <MKBox component="img" src={client2Logo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={8} md={3} lg={3}>
            <MKBox component="img" src={client8Logo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={8} md={3} lg={3}>
            <MKBox component="img" src={client9Logo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
