/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Gal1 from "assets/images/galery-1.jpeg";
import Gal2 from "assets/images/galery-2.jpeg";
import Gal3 from "assets/images/galery-3.jpeg";
import Gal4 from "assets/images/galery-4.jpeg";
import Gal5 from "assets/images/galery-5.jpeg";
import Gal6 from "assets/images/galery-6.jpeg";
import Gal7 from "assets/images/galery/gal7-pot.jpeg";
import Gal8 from "assets/images/galery/gal8.jpeg";
import Gal9 from "assets/images/galery/gal9-pot.jpeg";
import Gal10 from "assets/images/galery/gal10-pot.jpeg";
import Gal11 from "assets/images/galery/gal11.jpeg";
import Gal12 from "assets/images/galery/gal12-pot.jpeg";
import Gal13 from "assets/images/galery/gal13-pot.jpeg";
import Gal14 from "assets/images/galery/gal14.jpeg";
import Gal15 from "assets/images/galery/gal15.jpeg";
import Gal16 from "assets/images/galery/gal16.jpeg";
import Gal17 from "assets/images/galery/gal17.jpeg";
import Gal18 from "assets/images/galery/gal18.jpeg";
import Gal19 from "assets/images/galery/gal19.jpeg";

export default [
  {
    image: Gal1,
    //name: "Author Page",
  },
  {
    image: Gal3,
  },
  {
    image: Gal4,
  },
  {
    image: Gal5,
  },
  {
    image: Gal2,
  },
  {
    image: Gal7,
  },
  {
    image: Gal6,
  },
  {
    image: Gal8,
  },
  {
    image: Gal11,
  },
  {
    image: Gal9,
  },
  {
    image: Gal10,
  },

  {
    image: Gal12,
  },
  {
    image: Gal13,
  },
  {
    image: Gal14,
  },
  {
    image: Gal15,
  },
  {
    image: Gal16,
  },
  {
    image: Gal17,
  },
  {
    image: Gal18,
  },
  {
    image: Gal19,
  },
];
