/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Card from "@mui/material/Card";

// // Material Kit 2 React components
// import MKBox from "components/MKBox";

// // Material Kit 2 React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// // Author page sections
// import Profile from "pages/LandingPages/Author/sections/Profile";
// import Posts from "pages/LandingPages/Author/sections/Posts";
// import Contact from "pages/LandingPages/Author/sections/Contact";
// import Footer from "pages/LandingPages/Author/sections/Footer";

// // Routes
// import routes from "routes";

// // Images
// import bgImage from "assets/images/city-profile.jpg";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/LandingPages/Author/data/galeryData";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
//import bgImage from "assets/images/bg-about-us.jpg";

function Author() {
  const renderData = data.map(({ image, name, route }) => (
    <Grid item xs={10} md={4} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <Link to={route}>
        <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
      </Link>
    </Grid>
  ));

  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox component="section" py={6}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
          >
            {/* <MKBadge
              variant="contained"
              color="info"
              badgeContent="boost creativity"
              container
              sx={{ mb: 2 }}
            /> */}
            <MKTypography variant="h2" fontWeight="bold">
              Gallery
            </MKTypography>
            <MKTypography variant="body1" color="text">
              The easiest way to get started is to use one of our
              <br /> Following are the projects we have worked on.
            </MKTypography>
          </Grid>
        </Container>
        <Container sx={{ mt: { xs: 8, lg: 16 } }}>
          <Grid container spacing={3}>
            {renderData}
            {/* <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid> */}
            {/* <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                Presentation Galery for Company
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                These is just a small selection of the multiple possibitilies you have. Focus on the
                business, not on the design.
              </MKTypography>
            </MKBox>
          </Grid> */}
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Author;
