/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Gal1 from "assets/images/galery-1.jpeg";
import Gal2 from "assets/images/galery-2.jpeg";
import Gal3 from "assets/images/galery-3.jpeg";
import Gal4 from "assets/images/galery-4.jpeg";
import Gal5 from "assets/images/galery-5.jpeg";
import Gal6 from "assets/images/galery-6.jpeg";
import Gal15 from "assets/images/galery/gal15.jpeg";
import Gal16 from "assets/images/galery/gal16.jpeg";

export default [
  {
    image: Gal5,
    name: "",
    //route: "/pages/landing-pages/about-us",
  },
  {
    image: Gal2,
    //name: "Sign In Page",
    //route: "/pages/authentication/sign-in",
  },
  {
    image: Gal3,
  },
  {
    image: Gal4,
  },
  {
    image: Gal1,
  },
  {
    image: Gal6,
  },
  {
    image: Gal15,
  },
  {
    image: Gal16,
  },
];
